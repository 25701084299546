<template>
  <journal-goods-list
    :id="id"
    :show.sync="isShow"
    :headers="headers"
    :title="title"
    :wares="items"
  />
</template>
<script>
import JournalGoodsList from "@/components/journal/journal-goods-list.vue";
import {getCostString, getJournalDeclarationTitle} from "@/helpers/text";


export default {
  components:{
    JournalGoodsList
  },
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:true,
    },
    module:{
      type:String,
      required:true
    }
  },
  data(){
    return{
      declaration:{},
      items:[],
      headers:[
        {
          text:"№",
          value:"index"
        },
        {
          text:"Код товара",
          value:"tn_ved"
        },
        {
          text:"Цена в валюте",
          value:"invoice_cost"
        },
      ],
    }
  },
  computed:{
    isShow:{
      get(){
        return this.show
      },
      set(value){
        this.$emit('update:show', value)
      }
    },
    title(){
      return getJournalDeclarationTitle(this.declaration)
    },
  },
  watch:{
    show:{
      handler(value){
        if(value){
          this.fetchDocument()
        }
      },
      immediate:true
    }
  },
  methods:{
    setItems(wares){
      this.items = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          basis_for_calculation_json,
          invoice_currency_letter
        } = item
        const invoice_cost = getCostString(basis_for_calculation_json.invoice_cost, invoice_currency_letter)
        return {
          index:idx + 1,
          id,
          tn_ved,
          invoice_cost,
          invoice_currency_letter
        }
      })
    },
    errorHandler(err){
      if(err.response.status === 403){
        this.$error('Доступ запрещен')
      }else{
        this.$error()
      }
    },
    fetchDocument(){
      this.$store.dispatch(`dts/getDocumentInfo`, this.id).then(res => {
        this.declaration = res.data
        this.setItems(this.declaration.wares)
      }).catch(this.errorHandler)
    },
  }
}
</script>
